import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
} from 'react-router-dom';
import { ROUTES, REDIRECT_ROUTES } from 'constants/router';
import { settingsLoader } from 'utils/loaders/settings.loader';
import { myOrdersLoader } from 'utils/loaders/my-orders.loader';
import { PDFPresentation } from 'pages/pdf-presentation';
import { newTeamMemberLoader, teamMemberLoader } from 'utils/loaders/team-member.loader';
import { presentationsLoader } from 'utils/loaders/presentations.loader';
import { InvoiceGeneration } from 'pages/invoice-generation';
import { invoicesLoader } from 'utils/loaders/invoices.loader';
import { customerDetailsLoader } from 'utils/loaders/customer-details.loader';
import { customersLoader } from 'utils/loaders/customers.loader';
import { SignSkippedContract } from 'pages/sign-agreement/sign-agreement';
import { SkippedContractRoute } from 'components/skipped-contract-route';
import { featureFlagNames } from 'constants/feature-flag.constant';
import { SalesToolsLayout } from 'components/sales-tools-layout';
import { MyOrdersLayout } from 'pages/my-orders/my-orders-layout';
import { salesToolsLoader } from 'utils/loaders/sales-tools.loader';
import { AuthLayout } from '../components/auth-layout';
import { PageLayout } from '../components/page-layout';
import { RootLayout } from '../components/root-layout';
import { StoreCreationLayout } from '../components/store-creation-layout';
import { PrivateRoute } from '../components/private-route';
import { Dashboard } from '../pages/dashboard';
import { MyStoreOrders, CBSOrders } from '../pages/my-orders';
import { PromoCodes } from '../pages/promocodes';
import { CustomersAndLeads } from '../pages/customers-and-leads';
import { Activity } from '../pages/activity/';
import { PageLink } from '../pages/store-creation/page-link';
import { Brand } from '../pages/store-creation/brand';
import { Margin } from '../pages/store-creation/margin';
import { ProductSelection } from '../pages/store-creation/product-selection';
import { SignContract } from '../pages/store-creation/sign-contract';
import { Login } from '../pages/login';
import { CreatePassword } from '../pages/create-password';
import { ResetPassword } from '../pages/reset-password';
import { PasswordRecovery } from '../pages/password-recovery';
import { rootLoader } from '../utils/loaders/root.loader';
import { onboardingLoader } from '../utils/loaders/onboarding.loader';
import { SettingsLayout } from '../components/settings-layout';
import { CompanyAndBrand } from '../pages/settings/company-and-brand';
import { ProductsAndMargin } from '../pages/settings/products-and-margin';
import { PlanAndPayment } from '../pages/settings/plan-and-payment';
import { OperationHours } from '../pages/settings/operation-hours';
import { UserAndPermissions } from '../pages/settings/user-and-permissions';
import { CustomerProfile } from '../pages/customer-profile';
import { CompanyProfile } from '../pages/company-profile';
import { InvitationForbidden } from '../pages/invitation-forbidden';
import { InvitationInactive } from '../pages/invitation-inactive';
import { BuildACart } from '../pages/build-a-cart';
import { injectRouter } from '../utils/router-outside.utils';
import { ReferAMember } from '../pages/refer-a-member';
import { cartsLoader } from '../utils/loaders/carts.loader';
import { pageLoader } from '../utils/loaders/page.loader';
import { promocodesLoader } from '../utils/loaders/promocodes.loader';
import { activityLoader } from '../utils/loaders/activity.loader';
import { dashboardLoader } from '../utils/loaders/dashboard.loader';
import { referAMemberLoader } from '../utils/loaders/refer-a-member.loader';
import { authLoader } from '../utils/loaders/auth.loader';
import { storeCreationLoader } from '../utils/loaders/store-creation.loader';
import { ProfileSettings } from '../pages/profile-settings';
import { profileSettingsLoader } from '../utils/loaders/profile-settings.loader';
import { leadsLoader } from '../utils/loaders/leads.loader';
import { curatedBrandSiteLoader } from '../utils/loaders/curated-brand-site.loader';
import { CuratedBrandSite } from '../pages/curated-brand-site';
import { FeatureToggleProtectedRoute } from '../components/feature-toggle-protected-route';
import { TeamMemberDetails, TeamMemberMode } from '../pages/team-member-details';
import { AbilityRoute } from '../components/ability-route';
import { CustomerCompany } from '../pages/customer-company';
import { customerCompanyLoader } from '../utils/loaders/customer-company.loader';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RootLayout />} loader={rootLoader}>
      <Route
        path={REDIRECT_ROUTES.OLD_MY_ORDERS}
        element={<Navigate to={ROUTES.MY_STORE_ORDERS} replace />}
      />
      <Route element={<AbilityRoute />}>
        <Route element={<PrivateRoute />}>
          <Route element={<PageLayout />} path={ROUTES.INDEX} loader={pageLoader}>
            <Route
              path={ROUTES.DASHBOARD}
              index
              element={<Dashboard />}
              loader={dashboardLoader}
            />
            <Route element={<SalesToolsLayout />} loader={salesToolsLoader}>
              <Route
                path={ROUTES.BUILD_A_CART}
                element={<BuildACart />}
                loader={cartsLoader}
              />
              <Route
                path={ROUTES.PDF_PRESENTATION}
                element={<PDFPresentation />}
                loader={presentationsLoader}
              />
              <Route
                path={ROUTES.CURATED_BRAND_SITE}
                element={<CuratedBrandSite />}
                loader={curatedBrandSiteLoader}
              />
              <Route
                path={ROUTES.INVOICE_GENERATION}
                element={<InvoiceGeneration />}
                loader={invoicesLoader}
              />
            </Route>
            <Route element={<MyOrdersLayout />}>
              <Route
                path={ROUTES.MY_STORE_ORDERS}
                element={<MyStoreOrders />}
                loader={myOrdersLoader}
              />
              <Route
                path={ROUTES.CBS_ORDERS}
                element={<CBSOrders />}
                loader={myOrdersLoader}
              />
            </Route>
            <Route
              path={ROUTES.CUSTOMERS}
              element={<CustomersAndLeads />}
              loader={customersLoader}
            />
            <Route
              path={ROUTES.LEADS}
              element={<CustomersAndLeads />}
              loader={leadsLoader}
            />
            <Route
              path={ROUTES.ABANDONED_CHECKOUTS}
              element={<Activity />}
              loader={activityLoader}
            />
            <Route
              path={ROUTES.CUSTOMER_PROFILE}
              element={<CustomerProfile />}
              loader={customerDetailsLoader}
            />
            <Route path={ROUTES.COMPANY_PROFILE} element={<CompanyProfile />} />
            <Route
              path={ROUTES.PROMOCODES}
              element={<PromoCodes />}
              loader={promocodesLoader}
            />
            <Route
              path={ROUTES.REFER_A_MEMBER}
              element={<ReferAMember />}
              loader={referAMemberLoader}
            />
            <Route
              path={ROUTES.PROFILE_SETTINGS}
              element={<ProfileSettings />}
              loader={profileSettingsLoader}
            />
            <Route
              path={ROUTES.CUSTOMER_COMPANY}
              element={<CustomerCompany />}
              loader={customerCompanyLoader}
            />
            <Route element={<SettingsLayout />} loader={settingsLoader}>
              <Route
                path={ROUTES.SETTINGS_COMPANY_AND_BRAND}
                element={<CompanyAndBrand />}
              />
              <Route
                path={ROUTES.SETTINGS_PRODUCTS_AND_COMMISSION}
                element={<ProductsAndMargin />}
              />
              <Route
                path={ROUTES.SETTINGS_PLAN_AND_PAYMENT}
                element={<PlanAndPayment />}
              />
              <Route
                path={ROUTES.SETTINGS_OPERATION_HOURS}
                element={<OperationHours />}
              />
              <Route
                path={ROUTES.SETTINGS_USER_AND_PERMISSIONS}
                element={
                  <FeatureToggleProtectedRoute
                    featureFlag={featureFlagNames.RESELLER_INVITATION}
                  >
                    <UserAndPermissions />
                  </FeatureToggleProtectedRoute>
                }
              />
            </Route>
            <Route
              path={ROUTES.TEAM_MEMBER_DETAILS_EDIT}
              loader={teamMemberLoader}
              element={
                <FeatureToggleProtectedRoute
                  featureFlag={featureFlagNames.RESELLER_INVITATION}
                >
                  <TeamMemberDetails mode={TeamMemberMode.EDIT} />
                </FeatureToggleProtectedRoute>
              }
            />
            <Route
              path={ROUTES.TEAM_MEMBER_DETAILS_CREATE}
              shouldRevalidate={() => true}
              loader={newTeamMemberLoader}
              element={
                <FeatureToggleProtectedRoute
                  featureFlag={featureFlagNames.RESELLER_INVITATION}
                >
                  <TeamMemberDetails mode={TeamMemberMode.CREATE} />
                </FeatureToggleProtectedRoute>
              }
            />
          </Route>
        </Route>
      </Route>
      <Route element={<AuthLayout />} loader={authLoader}>
        <Route path={ROUTES.LOGIN} element={<Login />} />
        <Route path={ROUTES.CREATE_PASSWORD} element={<CreatePassword />} />
        <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
        <Route path={ROUTES.PASSWORD_RECOVERY} element={<PasswordRecovery />} />
        <Route path={ROUTES.INVITATION_INACTIVE} element={<InvitationInactive />} />
        <Route path={ROUTES.INVITATION_FORBIDDEN} element={<InvitationForbidden />} />
      </Route>
      <Route element={<AbilityRoute />} loader={storeCreationLoader}>
        <Route element={<PrivateRoute />}>
          <Route element={<SkippedContractRoute />}>
            <Route path={ROUTES.SIGN_AGREEMENT} element={<SignSkippedContract />} />
          </Route>
          <Route element={<StoreCreationLayout />} loader={onboardingLoader}>
            <Route path={ROUTES.STORE_CREATION_BRAND} element={<Brand />} />
            <Route path={ROUTES.STORE_CREATION_PAGE_LINK} element={<PageLink />} />
            <Route
              path={ROUTES.STORE_CREATION_PRODUCT_SELECTION}
              element={<ProductSelection />}
            />
            <Route path={ROUTES.STORE_CREATION_COMMISSION} element={<Margin />} />
            <Route path={ROUTES.STORE_CREATION_CONTRACT} element={<SignContract />} />
          </Route>
        </Route>
      </Route>
    </Route>,
  ),
);

injectRouter(router);
