import cn from 'classnames';
import {
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableRowCell,
  Typography,
  WhiteBox,
} from '@ezetech/swag-space-x';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { ROUTES } from 'constants/router';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { useAppSelector } from 'redux/store';
import { customerOrdersTableRowsSelector } from 'redux/selectors/customer-details.selector';
import { StatusRow } from 'pages/my-orders/components/order-card-item/components/status-row';
import { OrderFields, ORDERS_TABLE_HEADER } from 'interfaces/customer-details.interface';

import { Separator } from '../separator';
import css from './orders.module.scss';

export function Orders(): JSX.Element {
  const orders = useAppSelector(customerOrdersTableRowsSelector);
  const navigate = useNavigate();

  const onNumberClick =
    (search: string, isCBSOrder = false) =>
    () => {
      const path = {
        pathname: isCBSOrder ? ROUTES.CBS_ORDERS : ROUTES.MY_STORE_ORDERS,
        search: createSearchParams({ orderId: search }).toString(),
      };
      navigate(path);
    };

  return (
    <WhiteBox className={css.container}>
      <div className={css.header}>
        <Typography
          className={css.title}
          lineHeight="162%"
          fontType="bodyMdBold"
          color={COLORS.colorTextSubdued}
        >
          All orders placed
        </Typography>
      </div>
      <Separator />
      {orders.length === 0 && (
        <div className={css.footer}>
          <Typography
            className={css.title}
            lineHeight="162%"
            fontType="bodyMdBold"
            color={COLORS.colorTextSubdued}
          >
            No Orders
          </Typography>
        </div>
      )}
      {orders.length !== 0 && (
        <div className={css.tableWrapper}>
          <TableHeader className={css.tableHeader}>
            {ORDERS_TABLE_HEADER.map((cell) => (
              <TableHeaderCell
                id={cell.id}
                className={css[cell.id]}
                size={cell.size}
                key={`orders-header-${cell.id}`}
              >
                {cell.text}
              </TableHeaderCell>
            ))}
          </TableHeader>
          <Separator className={css.headerDivider} />
          {orders.map((row, position) => {
            return (
              <TableRow className={cn(css.tableRow)} key={`orders-${row.index}`}>
                {row.cells.map((cell, index) => (
                  <TableRowCell
                    className={cn(css[cell.id], {
                      [css.rowNumber]: cell.id === OrderFields.number,
                    })}
                    onClick={
                      cell.id === OrderFields.number
                        ? onNumberClick(String(cell.value), cell.isCBSOrder)
                        : () => null
                    }
                    key={`orders-${position}-${cell.id}-${index}`}
                    textColor={cell.textColor}
                    textWeight={cell.textWeight}
                    size={cell.size}
                    id={cell.id}
                    skipTooltip={cell.id === OrderFields.status}
                  >
                    {cell.id === OrderFields.status ? (
                      <StatusRow
                        className={css.inline}
                        trackingNumberLink=""
                        status={cell.value as string}
                        orderId={cell.orderId || ''}
                        itemId={cell.itemId || ''}
                        fromCustomerProfile={true}
                        isReviewPantonesByCustomerAllowed={Boolean(
                          cell.isReviewPantonesByCustomerAllowed,
                        )}
                        isReviewMockupsByCustomerAllowed={Boolean(
                          cell.isReviewMockupsByCustomerAllowed,
                        )}
                      />
                    ) : (
                      cell.text
                    )}
                  </TableRowCell>
                ))}
              </TableRow>
            );
          })}
        </div>
      )}
    </WhiteBox>
  );
}
