import { createSelector } from 'reselect';
import { getTimeMmmDdYyyy } from 'utils/date/date-formatter.util';
import {
  CUSTOMER_TYPES,
  ITransformedCell,
  OrderFields,
  ORDERS_TABLE_HEADER,
  ORDERS_TABLE_ROW,
  PAYMENT_METHODS,
  PAYMENT_METHODS_FREQUENCY,
  PAYMENT_METHODS_FREQUENCY_LABELS,
  PAYMENT_METHODS_LABELS,
  TAX_METHODS,
  TAX_METHODS_LABELS,
  ICustomerOrder,
} from 'interfaces/customer-details.interface';
import { SUBJECTS } from 'constants/ability.constant';
import { can } from 'boot/ability';
import { getItemStatusLabel, getNumWithLetterIndicator } from 'utils/items.util';
import { pluralize } from 'utils/string.util';
import {
  ICustomerProfileOrder,
  IItem,
  IProductionMockup,
} from 'interfaces/orders.interface';
import { PRODUCTION_MOCKUP_STATUSES } from 'constants/item.constants';
import { RootState } from '../store';
import { formatUser, formatUserName } from './customer-details.selector.utils';
import {
  isReviewMockupsByCustomerAllowedSelector,
  isReviewPantonesByCustomerAllowedSelector,
} from './settings.selectors';

export const customerDetailsSelector = (state: RootState) => state.customerDetails;
export const settingsSelector = (state: RootState) => state.settings;

export const customerProfileDetailsSelector = createSelector(
  customerDetailsSelector,
  (state) => state.user,
);

export const customerEditedProfileDetailsSelector = createSelector(
  customerDetailsSelector,
  (state) => state.editedUser,
);

export const customerStatisticDetailsSelector = createSelector(
  customerDetailsSelector,
  (state) => {
    const { avgOrderValue, lastOrderAt, totalSpent, totalOrders } = state.statistic;
    const viewOrdersAccess = can(
      SUBJECTS.VIEW_ORDERS.actions.VIEW_ALL_ORDERS,
      SUBJECTS.VIEW_ORDERS.value,
    );
    if (!viewOrdersAccess) {
      return {
        totalOrders: parseFloat(totalOrders.replace('$', '')) || 'N/A',
        avgOrderValue: avgOrderValue || 'N/A',
        totalSpent: parseFloat(totalSpent.replace('$', '')) || 'N/A',
        lastOrderAt: lastOrderAt
          ? getTimeMmmDdYyyy(new Date(lastOrderAt), {
              day: 'numeric',
              month: 'short',
              year: '2-digit',
            })
          : 'N/A',
      };
    }
    return {
      totalOrders,
      avgOrderValue,
      totalSpent,
      lastOrderAt: lastOrderAt
        ? getTimeMmmDdYyyy(new Date(lastOrderAt), {
            day: 'numeric',
            month: 'short',
            year: '2-digit',
          })
        : 'N/A',
    };
  },
);

export const customerOrdersDetailsSelector = createSelector(
  customerDetailsSelector,
  (state) => state.orders,
);

export const customerNotesDetailsSelector = createSelector(
  customerDetailsSelector,
  (state) => state.notes,
);

export const customerDetailsPageHeadingSelector = createSelector(
  customerProfileDetailsSelector,
  (user) => ({
    heading: formatUserName(user) || 'Customer page',
    subHeading: user ? user.email : '',
  }),
);

export const customerCompanyDetailsSelector = createSelector(
  customerProfileDetailsSelector,
  formatUser,
);

export const editedCustomerCompanyDetailsSelector = createSelector(
  customerEditedProfileDetailsSelector,
  (user) => formatUser(user, true),
);

export const customerPaymentMethodSelector = createSelector(
  customerProfileDetailsSelector,
  (user) => {
    const fallbackPayment = {
      tax: {
        value: TAX_METHODS.TAX_CHANGE,
        label: TAX_METHODS_LABELS[TAX_METHODS.TAX_CHANGE],
      },
      paymentMethod: {
        value: PAYMENT_METHODS.CREDIT_CARD,
        label: PAYMENT_METHODS_LABELS[PAYMENT_METHODS.CREDIT_CARD],
      },
      paymentMethodFrequency: {
        value: PAYMENT_METHODS_FREQUENCY.ONE_TIME,
        label: PAYMENT_METHODS_FREQUENCY_LABELS[PAYMENT_METHODS_FREQUENCY.ONE_TIME],
      },
    };

    if (!user) {
      return fallbackPayment;
    }

    const { paymentMethod, paymentMethodFrequency } = user;

    if (!paymentMethod || !paymentMethodFrequency) {
      return fallbackPayment;
    }

    return {
      tax: {
        value: TAX_METHODS.TAX_CHANGE,
        label: TAX_METHODS_LABELS[TAX_METHODS.TAX_CHANGE],
      },
      paymentMethod: {
        value: paymentMethod,
        label: PAYMENT_METHODS_LABELS[paymentMethod],
      },
      paymentMethodFrequency: {
        value: paymentMethodFrequency,
        label: PAYMENT_METHODS_FREQUENCY_LABELS[paymentMethodFrequency],
      },
    };
  },
);

export const customerPaymentMethodPopupInfoSelector = (value: string) => {
  switch (value) {
    case PAYMENT_METHODS.NET_15:
    case PAYMENT_METHODS.NET_30:
    case PAYMENT_METHODS.NET_45:
    case PAYMENT_METHODS.NET_60: {
      return {
        title: 'NET 15 / 30 / 45 / 60',
        description:
          'You will be able to allow your customers to pay via Net 15 / 30 / 45 / 60 on a one time order basis, or for all orders. Once your customers checkout via Net 15 / 30 / 45 / 60, Swag.com will charge our portion (not including your commission), to the credit card you have placed on file. You will then be responsible for collecting the full amount from your customers. Are you sure you want to continue?',
      };
    }

    case PAYMENT_METHODS.ACH: {
      return {
        title: 'ACH',
        description:
          'You will be able to allow your customers to pay via ACH on a one time order basis, or for all orders. Once your customers checkout via ACH, Swag.com will charge our portion (not including your commission), to the credit card you have placed on file. You will then be responsible for collecting the full amount from your customers. Are you sure you want to continue?',
      };
    }
    default: {
      return {
        title: 'Credit Card',
        description:
          'By default all of your customers will be default to set to credit card payment. Once your customers pay through credit card, Swag.com will collect the full amount, and then pay you your commission.',
      };
    }
  }
};

export const customerOrdersTableRowsSelector = createSelector(
  customerOrdersDetailsSelector,
  isReviewPantonesByCustomerAllowedSelector,
  isReviewMockupsByCustomerAllowedSelector,
  (orders, isReviewPantonesAllowed, isReviewMockupAllowed) => {
    const rows: {
      cells: ITransformedCell[];
      index: number;
    }[] = [];
    const makeCells = (
      order: ICustomerOrder,
      product?: ICustomerOrder['products'][0],
    ) => {
      const isReviewPantonesByCustomerAllowed =
        order.isSpecificReviewPantonesByCustomerAllowed === null
          ? isReviewPantonesAllowed
          : order.isSpecificReviewPantonesByCustomerAllowed;

      const isReviewMockupsByCustomerAllowed =
        order.isSpecificReviewMockupsByCustomerAllowed === null
          ? isReviewMockupAllowed
          : order.isSpecificReviewMockupsByCustomerAllowed;

      const cells = ORDERS_TABLE_HEADER.map((cell) => {
        const cellParams = ORDERS_TABLE_ROW[cell.id] || {};
        if (cell.id === OrderFields.number) {
          const value = order[cell.id];

          return {
            ...cellParams,
            value,
            id: cell.id,
            text: getNumWithLetterIndicator(product?.num, product?.letterIndicator),
            isCBSOrder: order.isCBSOrder,
          };
        }

        if (cell.id === OrderFields.status) {
          const value = product
            ? getItemStatusLabel({
                status: product.status,
                statusCode: product.statusCode,
                isReviewPantonesByCustomerAllowed,
                isReviewMockupsByCustomerAllowed,
              })
            : 'Completed';

          return {
            ...cellParams,
            value,
            orderId: order.id,
            itemId: product?.id,
            id: cell.id,
            text: value,
            isReviewPantonesByCustomerAllowed,
            isReviewMockupsByCustomerAllowed,
            isCBSOrder: order.isCBSOrder,
          };
        }

        if (cell.id === OrderFields.products) {
          const value = product
            ? product.name
            : pluralize(order[cell.id].length, 'Product');
          return {
            ...cellParams,
            value,
            id: cell.id,
            text: value,
            isCBSOrder: order.isCBSOrder,
          };
        }

        if (cell.id === OrderFields.paidAt) {
          const value = order[cell.id];

          return {
            ...cellParams,
            id: cell.id,
            value,
            text: value
              ? getTimeMmmDdYyyy(new Date(value), {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })
              : 'N/A',
            isCBSOrder: order.isCBSOrder,
          };
        }

        if (cell.id === OrderFields.total) {
          const value = order.total;
          return {
            ...cellParams,
            value,
            id: cell.id,
            text: value,
            isCBSOrder: order.isCBSOrder,
          };
        }

        const value = cell.id ? order[cell.id] : '';

        return {
          ...cellParams,
          value,
          id: cell.id,
          text: value?.toString() ?? 'N/A',
          isCBSOrder: order.isCBSOrder,
        };
      });

      return cells;
    };

    let position = 0;
    orders.forEach((order) => {
      order?.products?.forEach((product) => {
        const cells = makeCells(order, product);
        rows.push({
          cells,
          index: (position += 1),
        });
      });
    });

    return rows;
  },
);

export const userErrorsSelector = createSelector(customerDetailsSelector, (state) => {
  return state.userErrors;
});

export const addressErrorsSelector = createSelector(customerDetailsSelector, (state) => {
  return state.addressErrors;
});

export const customerTypeDetailsSelector = createSelector(
  customerProfileDetailsSelector,
  (user) => {
    if (!user?.type) {
      return 'N/A';
    }

    return CUSTOMER_TYPES[user.type];
  },
);

export const orderByIdSelector = createSelector(
  customerOrdersDetailsSelector,
  (_: RootState, { orderId }: { orderId: string }) => orderId,
  (orders, orderId) => {
    return orders.find(({ id }) => id === orderId) as unknown as ICustomerProfileOrder;
  },
);

export const orderItemsSelector = createSelector(orderByIdSelector, (order) => {
  return order?.products || [];
});

export const itemByIdSelector = createSelector(
  orderItemsSelector,
  (_: RootState, { itemId }: { itemId: string }) => itemId,
  (items, itemId) => {
    return items.find((item) => item.id === itemId) as unknown as IItem;
  },
);

export const getInfoForReviewColorsFromCustomerProfile =
  ({ orderId, itemId }: { orderId: string; itemId: string }) =>
  (state: RootState) => {
    const item = itemByIdSelector(state, {
      orderId,
      itemId,
    });
    return item;
  };

export const itemDataForProductionMockupForCustomerProfile =
  ({ orderId, itemId }: { orderId: string; itemId: string }) =>
  (state: RootState) => {
    const item = itemByIdSelector(state, { orderId, itemId });
    const { number } =
      orderByIdSelector(state, {
        orderId,
      }) || '';
    const customerMockups = (item?.pantones || []).map(
      ({ id, location, mockupPreview }) => ({
        id,
        location,
        mockupPreview,
      }),
    );

    if (!customerMockups.length) {
      customerMockups.push({
        mockupPreview: item?.imageUrl,
        location: 'full-design',
        id: 'full-design',
      });
    }

    const pendingProductionMockup = item?.productionMockups?.reduce<{
      id: string;
      status: string;
      fileName: string;
    }>(
      (res, mockup: IProductionMockup) => {
        if (res.id) {
          return res;
        }
        if (mockup.status === PRODUCTION_MOCKUP_STATUSES.PENDING_RESELLER_APPROVAL) {
          return {
            id: mockup.id,
            status: mockup.status,
            fileName: mockup.fileName,
          };
        }
        return res;
      },
      { id: '', status: '', fileName: '' },
    );

    return {
      productName: item?.name,
      orderNum: number,
      productionMockup: pendingProductionMockup ?? [],
      customerMockups,
    };
  };

export const isDefaultPantoneSettingsOverriddenSelector = createSelector(
  customerEditedProfileDetailsSelector,
  (editedUser) => {
    const hasAccessForPantoneAndMockupApprove = can(
      SUBJECTS.EDIT_CUSTOMERS_DETAILS.actions.PANTONE_AND_MOCKUP_APPROVAL,
      SUBJECTS.EDIT_CUSTOMERS_DETAILS.value,
    );

    return (
      editedUser?.isSpecificReviewPantonesByCustomerAllowed !== null &&
      hasAccessForPantoneAndMockupApprove
    );
  },
);

export const isDefaultMockupSettingsOverriddenSelector = createSelector(
  customerEditedProfileDetailsSelector,
  (editedUser) => {
    const hasAccessForPantoneAndMockupApprove = can(
      SUBJECTS.EDIT_CUSTOMERS_DETAILS.actions.PANTONE_AND_MOCKUP_APPROVAL,
      SUBJECTS.EDIT_CUSTOMERS_DETAILS.value,
    );

    return (
      editedUser?.isSpecificReviewMockupsByCustomerAllowed !== null &&
      hasAccessForPantoneAndMockupApprove
    );
  },
);

export const isCalculatedReviewMockupsByCustomerAllowedSelector = createSelector(
  customerEditedProfileDetailsSelector,
  settingsSelector,
  (editedUser, settings) => {
    return Boolean(
      editedUser?.isSpecificReviewMockupsByCustomerAllowed ??
        settings.companySettings.isReviewMockupsByCustomerAllowed,
    );
  },
);

export const isSpecificReviewMockupsByCustomerAllowedSelector = createSelector(
  customerEditedProfileDetailsSelector,
  (editedUser) => editedUser?.isSpecificReviewMockupsByCustomerAllowed,
);

export const isSpecificReviewPantonesByCustomerAllowedSelector = createSelector(
  customerEditedProfileDetailsSelector,
  (editedUser) => editedUser?.isSpecificReviewPantonesByCustomerAllowed,
);

export const isCalculatedReviewPantonesByCustomerAllowedSelector = createSelector(
  customerEditedProfileDetailsSelector,
  settingsSelector,
  (editedUser, settings) => {
    return Boolean(
      editedUser?.isSpecificReviewPantonesByCustomerAllowed ??
        settings.companySettings.isReviewPantonesByCustomerAllowed,
    );
  },
);
